import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate, useNavigate} from 'react-router-dom';
import './App.css';
import GameAlphabet from "./games/gameAlphabet";
import SignIn from './views/SignIn';
import Login from './views/login';
import { validateToken } from './api/api';
import GameSelector from './views/GameSelector';
import GameWords from './games/gameWords';
import StartView from './views/StartView';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import RequestPasswordReset from './views/RequestPasswordReset';
import ConfirmPasswordReset from "./views/ConfirmPasswordReset";

function App() {
    const [isAuth, setIsAuth] = useState(false)

    useEffect(  () => {
        async function refreshToken() {
            const auth = localStorage.getItem('pocketbase_auth');
            if (!auth){
                setIsAuth(false)
                return
            }

            const authObject = JSON.parse(auth);
            await validateToken(authObject.token)
                .then(data => data).then(res => {
                    if (res.token){
                        setIsAuth(true)
                        localStorage.setItem('pocketbase_auth', JSON.stringify(res))
                    }
                })
                .catch(error => {
                    console.log(error)
                    setIsAuth(false)
                });
        }
        refreshToken()})

    return (
        <Router>
            <div className="full-size">
                {/* Routing logic */}
                <Routes>
                    <Route element={<PrivateRoute/>}>
                        <Route
                            path="/estadisticas"
                            element={
                                <Dashboard/>
                            }
                        />
                        <Route
                            path="/perfil"
                            element={
                                <Profile/>
                            }
                        />
                        <Route 
                            path="/"
                            element={<GameSelector/>}/>
                        <Route
                            path="/deletrear"
                            element={<StartView 
                                redirectTo='/deletrear/jugar' 
                                text={<>Deberás <span>deletrear 5 palabras</span> aleatorias</>}
                                />
                            }
                        />
                        <Route
                            path="/deletrear/jugar"
                            element={<GameAlphabet/>}
                        />
                        <Route
                            path="/palabras"
                            element={
                                <StartView 
                                    redirectTo='/palabras/jugar' 
                                    text={<>Deberás <span>señar 5 palabras</span> aleatorias</>}
                                    />
                            }
                        />
                        <Route
                            path="/palabras/jugar"
                            element={<GameWords />}/>
                    </Route>
                    
                    <Route
                        path="/ingresar"
                        element={<Login setIsAuth={setIsAuth}/>}/>
                    <Route
                        path="/registrar"
                        element={<SignIn/>}
                    />
                    <Route
                        path="/restablecer-contraseña"
                        element={<RequestPasswordReset />}
                    />
                    <Route
                        path="/confirmar-contraseña"
                        element={<ConfirmPasswordReset />}
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
